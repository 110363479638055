<template>
  <div class="budget">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Budget'
}
</script>

<style scoped>
  .budget {
    min-height:76%;
    display: flex;
  }
</style>
